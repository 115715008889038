<template>
  <v-col>
    <v-card flat>
      <v-card-text>
        <full-calendar 
          :options="calendarOption" />
      </v-card-text>
      <v-card-actions>
        <dir>
          <v-chip
            small
            class="ma-2"
            color="deep-orange darken-3 white--text">
            failed
          </v-chip>
          <v-chip
            small
            class="ma-2"
            color="yellow accent-2">
            canceled
          </v-chip>
          <v-chip
            small
            class="ma-2"
            color="cyan darken-1 white--text">
            scheduled
          </v-chip>
          <!-- <v-chip
            small
            class="ma-2"
            color="purple darken-3 white--text">
            in-progress
          </v-chip> -->
          <v-chip
            small
            class="ma-2"
            color="brown darken-1 white--text">
            skipped
          </v-chip>
          <v-chip
            small
            class="ma-2"
            color="light-green darken-1 white--text">
            completed
          </v-chip>
          <v-chip
            small
            class="ma-2"
            color="indigo lighten-5">
            new job
          </v-chip>
        </dir>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="openEventView"
      max-width="650px"
      persistent>
      <v-card
        min-width="500px"
        flat>
        <v-toolbar
          flat
          :color="selectedEvent.color">
          <v-btn icon>
            <v-icon color="white">mdi-message-bulleted</v-icon>
          </v-btn>
          <v-toolbar-title v-html="selectedEvent.name" />
          <v-spacer />
          <span
            v-if="loadingExport !== false"
            class="custom-loader">
            <v-icon color="white">mdi-cached</v-icon>
          </span>
          <v-menu
            bottom
            left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-subheader>Menu</v-subheader>
              <v-list-item-group>
                <v-list-item
                  dense
                  :disabled="selectedEvent.status === 'completed'"
                  @click="openRetainJob = true">
                  <v-list-item-content>
                    <v-list-item-title>Retain job</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item 
                  dense
                  :disabled="selectedEvent.status === 'completed'"
                  @click="exportCustomerFailedList(selectedEvent.id)">
                  <v-list-item-content>
                    <v-list-item-title>Export customer list not been send</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-card-text>
          <v-row class="mx-2">
            <v-col cols="12" 
              class="d-flex justify-space-between">
                <strong class="mr-2 title">Customers Total:</strong><div class="title"
                  v-html="selectedEvent.totalEstimateIds" />
                <strong class="mr-2 title">Estimate Total:</strong><div class="title"
                  v-html="selectedEvent.totalIds" />
            </v-col>
            <v-col cols="6">
             <strong class="mr-2">Title En:</strong><div v-html="selectedEvent.titleEn" />
                <strong class="mr-2">Message En:</strong><div v-html="selectedEvent.messageEn" />
            </v-col>
            <v-col cols="6">
                <strong class="mr-2">Title Th:</strong><div v-html="selectedEvent.titleTh" />
              
                <strong class="mr-2">Message Th:</strong><div v-html="selectedEvent.messageTh" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <strong class="mr-2">Deep Link:</strong><div v-html="selectedEvent.deepLink" />
            </v-col>
            <v-col cols="6">
              <v-img 
                v-if="selectedEvent.imageId !== ''"
                :src="selectedEvent.imageId"
                aspect-ratio="1.7"
                contain></v-img>
            </v-col>
          </v-row>
          <v-container>
            <v-chip-group
              column>
              <v-chip small
                v-for="(job, index) in selectedEvent.tasks"
                :key="index"
                :color="job.color">
                {{ job.startTime | formatTime }} - {{ job.endTime | formatTime }} | Amount : {{  job.amount }}
              </v-chip>
            </v-chip-group>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="secondary"
            @click="openEventView = false">
            CLOSE
          </v-btn>
          <v-btn
            text
            color="error"
            :disabled="selectedEvent.status === 'completed'"
            @click="openCancelJob = true">
            CANCEL JOB
          </v-btn>
          <v-spacer />
          <h6 class="caption">API MODE : {{ selectedEvent.api }}</h6>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="openRetainJob"
      max-width="550px"
      persistent>
      <v-card>
        <v-toolbar
          flat
          :color="selectedEvent.color">
          <v-toolbar-title>ReSchedule Job</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- <v-card-subtitle>Push message to customer list not been send. In case job failed.</v-card-subtitle> -->
        <v-card-text class="mt-5">
          <v-text-field
            label="Job Name"
            v-model="selectedEvent.name"
            dense
            outlined
            required />
          <div class="d-flex justify-space-between mb-6">
            <v-menu
              v-model="jobDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  label="Date Start Job"
                  hint="DD/MM/YYYY format"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on" />
              </template>
              <v-date-picker
                v-model="date"
                no-title
                @input="jobDate = false" />
            </v-menu>
            <v-text-field
                dense
                class="pt-0"
                v-model="time"
                :rules="[rules.timeFormat]"
                label="Job Time"
                hint="sample format time 09:00"
                persistent-hint
                prepend-icon="mdi-clock-time-four-outline" />
          </div>
          <v-text-field
            label="Maximum Push Per Job"
            v-model.number="retainValue.maxPushPerJob"
            dense
            outlined
            required />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="openRetainJob = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="retainJob">
            Re-Schedule Job
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="openCancelJob"
      max-width="550px"
      persistent>
      <v-card>
        <v-card-title>Reason for cancel job.</v-card-title>
        <v-card-text>
          <v-select
            :items="reasonOfCancel"
            outlined
            v-model.trim="cancelJob.reason"
            label="Reason"></v-select>
          <!-- <v-text-field
            label="Reason"
            v-model.trim="cancelJob.reason"
            counter="50"
            dense
            outlined
            required /> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="error"
            @click="cancelJobProcess">
            Submit
          </v-btn>
          <v-btn
            text
            @click="openCancelJob = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { mapGetters } from 'vuex';
import * as moment from 'moment';

export default {
  name: 'jobCalendar',
  props: {
    jobList: {
      type: Array
    }
  },
  components: {
    FullCalendar,
  },

  data () {
    return {
      date: moment().format('YYYY-MM-DD'),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      openEventView: false,
      openRetainJob: false,
      openCancelJob: false,
      time: null,
      jobDate: false,
      calendarOption: {
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin ],
        initialView: 'dayGridMonth',
        headerToolbar: { 
          start: 'prev next today',
          center: 'title',
          end: 'dayGridMonth timeGridWeek timeGridDay listWeek'
        },
        eventTimeFormat:{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        views: {
          timeGridWeek: {
            slotEventOverlap: true,
            type: 'timeGrid',
          }
        },
        eventClick: this.getJobInfo,
        height: 700,
        dayMaxEvents: true,
        // navLinks: true,
        events: this.jobList,
      },
      rules: {
        timeFormat: value => {
            const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
            return (
              pattern.test(value) ||
              'Please, check time 24Hr. patten. Ex: "09:00", "13:00"'
            );
          },
      },
      selectedEvent: {
        id: '',
        name: '',
        totalEstimateIds: 0,
        totalIds: 0,
        titleEn: '',
        messageEn: '',
        titleTh: '',
        messageTh: '',
        deepLink: '',
        imageId: '',
        tasks: [],
        status: '',
        color: '',
        colorSubTask: '',
        api: ''
      },
      retainValue: {
        jobId: '',
        name: '',
        startTime: '',
        maxPushPerJob: 0
      },
      cancelJob: {
        id: '',
        reason: '',
      },
      loadingExport: false,
      reasonOfCancel: [
        'Reschedule slot',
        'High traffic',
        'Stop job by owner',
        'Incorrect data',
        'Others'
      ]
    }
  },

  computed: {
    ...mapGetters({
      jobInfo: 'jobManagement/GET_JOB'
    }),

    computedDateFormatted() {
      this.jobInfo.dateJob = this.formatDate(this.date);
      return this.formatDate(this.date);
    },
  },

  watch: {
    time(val) {
      const dayStart = new Date(`${this.date}T${val}`);

      this.retainValue.startTime = Date.parse(dayStart);
    },
  },

  methods: {
    async cancelJobProcess() {
      this.cancelJob.id = this.selectedEvent.id;
      await this.$store.dispatch('jobManagement/DELETE_JOB_BY_ID', this.cancelJob)
        .then(() => { 
          this.openCancelJob = false
          this.openEventView = false
        });
    },

    async retainJob() {
      this.retainValue.name = this.selectedEvent.name;
      this.retainValue.jobId = this.selectedEvent.id;
      await this.$store.dispatch('jobManagement/CREATE_JOB_RETAIN', this.retainValue);
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    
    async getJobInfo (events) {
      await this.$store.dispatch('jobManagement/GET_JOB_BY_ID', events.event.id);

      if (this.jobInfo !== undefined) {
        this.openEventView = true;
        this.selectedEvent.id = events.event.id;
        this.selectedEvent.name = this.jobInfo.job.name;
        this.selectedEvent.totalEstimateIds = this.jobInfo.job.totalEstimateIds;
        this.selectedEvent.totalIds = this.jobInfo.job.totalIds;
        this.selectedEvent.titleEn = this.jobInfo.job.titleEn;
        this.selectedEvent.titleTh = this.jobInfo.job.titleTh;
        this.selectedEvent.messageEn = this.jobInfo.job.messageEn;
        this.selectedEvent.messageTh = this.jobInfo.job.messageTh;
        this.selectedEvent.deepLink = this.jobInfo.job.deepLink;
        this.selectedEvent.imageId = this.jobInfo.job.imageId;
        this.selectedEvent.tasks = this.jobInfo.job.tasks.map(s => {
                                      this.checkSubTaskStatus(s.status)
                                      return {
                                        ...s,
                                        color: this.selectedEvent.color
                                      }
                                    });
        this.selectedEvent.status = this.jobInfo.job.status;
        this.selectedEvent.api = this.jobInfo.job.api
        // this.selectedEvent.color = this.jobInfo.job.color;
        this.checkStatus(this.jobInfo.job.status);
      }
    },

    async exportCustomerFailedList(jobId) {
      this.loadingExport = true;
      await this.$store.dispatch('jobManagement/EXPORT_CUSTOMER_FIELD_LIST', jobId)
        .then(() => { this.loadingExport = false } );
    },

    checkStatus(status) {
      switch (status) {
          case 'failed':
            this.selectedEvent.color = 'deep-orange darken-3 white--text';
            break;

          case 'canceled':
            this.selectedEvent.color = 'yellow accent-2';
            break 
          
          case 'scheduled':
            this.selectedEvent.color = 'cyan darken-1 white--text';
            break;
            
          case 'in-progress':
            this.selectedEvent.color = 'purple darken-3 white--text';
            break;
            
          case 'skipped':
            this.selectedEvent.color = 'brown darken-1 white--text';
            break;
            
          case 'completed':
            this.selectedEvent.color = 'light-green darken-1 white--text';
            break;

          default:
            this.selectedEvent.color = 'indigo lighten-5 lighten-5';
            break;
        }
    },

    checkSubTaskStatus(status) {
      switch (status) {
        case 'failed':
          this.selectedEvent.color = 'deep-orange darken-3 white--text';
          break;
        case 'canceled':
          this.selectedEvent.color = 'yellow accent-2'; // yellow accent-2
          break 
        
        case 'scheduled':
          this.selectedEvent.color = 'cyan darken-1 white--text';
          break;
          
        case 'in-progress':
          this.selectedEvent.color = 'purple darken-3 white--text';
          break;
          
        case 'skipped':
          this.selectedEvent.color = 'brown darken-1 white--text';
          break;
          
        case 'completed':
          this.selectedEvent.color = 'light-green darken-1 white--text';
          break;
        default:
          this.selectedEvent.color = 'indigo lighten-5 lighten-5';
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>